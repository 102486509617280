/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageWhatsappContact/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import TemplatePagesThank from "@components/pageThanks/pageThanksGeneral/template"
import Form from "@components/pageFunctionalities/pageWhatsappContact/components/form"

const TemplatePageWhatsappContact = ({ location }) => {

  const [confirm, setConfirm] = useState(false)
  const onClickConfirm = () => {
    setConfirm(true)

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        <div className="container">
          {confirm ? (
            <div>
              <TemplatePagesThank location={location} text="En breves momentos un especialista se pondrá en contacto contigo para empezar a implementar la API de WhatsApp Business." />
            </div>
          ) : (
            <div>
              <Form data={data} location={location} confirm={confirm}
                onClickConfirm={onClickConfirm} />
            </div>
          )
          }

          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageWhatsappContact

